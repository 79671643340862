<template>
  <div class="admin">
    <div class="header flex justify-between items-center px-8">
      <div>EWH.Admin</div>
      <div class="right">
        <div @click="$router.push('/admin/imports')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div ref="wrapper" class="wrapper" v-if="importData !== null">
      <h2 class="mb-4">Import-Report</h2>
      <div class="flex flex-col mb-4">
        <label class="text-xs font-bold">Zeit</label>
        <input
          type="text"
          :value="createdString"
          class="w-full"
          disabled="true"
        />
      </div>
      <div class="flex flex-col mb-4">
        <label class="text-xs font-bold">Start-Zeit</label>
        <input
          type="text"
          :value="startedString"
          class="w-full"
          disabled="true"
        />
      </div>
      <div class="flex flex-col mb-4">
        <label class="text-xs font-bold">End-Zeit</label>
        <input
          type="text"
          :value="endedString"
          class="w-full"
          disabled="true"
        />
      </div>

      <div class="flex flex-col mb-4">
        <label for="timestamp" class="text-xs font-bold">Status</label>
        <input
          type="text"
          :value="importData.status"
          class="w-full"
          disabled="true"
        />
      </div>

      <div class="flex flex-col mb-4">
        <p class="text-xs font-bold">Dateien</p>
        <div class="bg-gray-100 mb-4 py-2">
          <p class="text-xs">
            <span class="font-bold">Anzahl:</span>
            {{ importData.files?.length || 0 }}
          </p>
          <p class="text-xs">
            <span class="font-bold">Übersprungen:</span>
            {{ importDataFilesSkipped || 0 }}
          </p>
          <p class="text-xs">
            <span class="font-bold">Fehlerhaft:</span>
            {{ importDataFilesError || 0 }}
          </p>
          <p class="text-xs">
            <span class="font-bold">Bearbeitet:</span>
            {{ importDataFilesImported || 0 }}
          </p>
        </div>
        <ul v-if="importData.files !== undefined">
          <li
            v-for="(file, index) in importData.files"
            :key="`import_${index}`"
            class="bg-gray-200 mb-2 py-2"
          >
            <p class="text-xs">
              <span class="font-bold w-24 inline-block">Name:</span>
              <a
                v-if="file.status === 'IMPORTED'"
                class="font-bold cursor-pointer"
                @click="$router.push(`/admin/articles/${file.articleId}`)"
                >{{ file.name }}</a
              >
              <span v-else>{{ file.name }}</span>
            </p>
            <p class="text-xs">
              <span class="font-bold w-24 inline-block">Typ:</span>
              <span>{{ file.type }}</span>
            </p>
            <p class="text-xs">
              <span class="font-bold w-24 inline-block">Status:</span>
              <span>{{ file.status }}</span>
            </p>
            <p v-if="file.status !== 'IMPORTED'" class="text-xs">
              <span class="font-bold">Message:</span>
              {{ file.message }}
            </p>
          </li>
        </ul>
      </div>

      <button
        @click="deleteImport"
        class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
      >
        Report löschen
      </button>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import { createInstance, resource } from "vuex-pagination"
import axios from "axios"
import Article from "@/models/article"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL
//import { bus } from "@/main";

export default {
  name: "admin-import",
  components: {
    RoundButton,
  },
  data() {
    return {
      importData: null,
      importDataFilesSkipped: 0,
      importDataFilesError: 0,
      importDataFilesImported: 0,
    }
  },
  computed: {
    createdString() {
      return moment(this.importData.created).format("DD.MM.YYYY HH:mm:ss")
    },
    startedString() {
      return this.importData.ended
        ? moment(this.importData.started).format("DD.MM.YYYY HH:mm:ss")
        : ""
    },
    endedString() {
      return this.importData.ended
        ? moment(this.importData.ended).format("DD.MM.YYYY HH:mm:ss")
        : ""
    },
  },
  created() {
    //this.$store.dispatch('getTags');
    //this.$store.dispatch('getArticles');
  },
  updated() {
    //debugger;
  },
  async mounted() {
    console.log("mounted", this.$route.params.importId)

    const response = await axios.get(
      `${API_URL}admin_imports/${this.$route.params.importId}`,
      { headers: authHeader() }
    )
    console.log("import", response)
    this.importData = response.data

    this.importDataFilesSkipped = this.importData.files?.filter(
      file => file.status === "SKIPPED"
    ).length
    this.importDataFilesError = this.importData.files?.filter(
      file => file.status === "FAILED"
    ).length
    this.importDataFilesImported = this.importData.files?.filter(
      file => file.status === "IMPORTED"
    ).length
  },
  methods: {
    async deleteImport() {
      if (!confirm("Import-Report löschen, sicher?")) return
      const response = await axios.delete(
        `${API_URL}admin_imports/${this.$route.params.importId}`,
        { headers: authHeader() }
      )
      if (response.status === 204) {
        this.$router.push("/admin/imports")
      }
    },
  },
}
</script>
<style scoped lang="scss">
.logout {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}
.newTag {
  width: 100%;
  margin: 0 auto;
  input {
    padding: 4px;
    margin: 4px;
  }
}
.admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .tags {
    width: 100%;
    margin: 0 auto;
  }
  .wrapper {
    position: absolute;
    background: $grey;
    padding: 30px;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
  }
}
</style>
