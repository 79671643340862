<script>
export default {
  props: ["data", "index"],
}
</script>

<template>
  <div>
    <a
      v-for="role in data.roles"
      :key="`tag-role-${role._id}`"
      class="underline cursor-pointer"
      @click="$router.push(`/admin/roles/${role._id}`)"
      >{{ role.name }}</a
    >
  </div>
</template>
