import { Model } from "@vuex-orm/core"
// eslint-disable-next-line
//import ArticleTag from '@/models/articleTag';
//import Tag from '@/models/tag';

export default class Article extends Model {
  // entity is a required property for all models.
  static entity = "articles"

  static primaryKey = "_id"

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(""),
      hash: this.attr(""),
      year: this.attr(""),
      month: this.attr(""),
      day: this.attr(""),
      tags: this.attr(""),
      content: this.attr(""),
      notes: this.attr(""),
      rating: this.attr(""),
      lat: this.attr(""),
      lon: this.attr(""),
      //tags: this.belongsToMany(Tag, ArticleTag, 'article_id', 'tag_id')
    }
  }
}
