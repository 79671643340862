<template>
  <div class="admin">
    <div class="header flex justify-between items-center px-8">
      <div>EWH.Admin - Tag bearbeiten</div>
      <div class="right">
        <div @click="$router.push('/admin/tags')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div ref="wrapper" class="wrapper" v-if="tag !== null">
      <label class="text-xs font-bold">Aktionen</label>
      <div class="p-4 bg-white rounded-lg mb-4">
        <button
          @click="updateTag"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-green-600"
        >
          Tag speichern
        </button>
      </div>
      <div class="flex flex-col mb-4">
        <label for="key" class="text-xs font-bold">Key</label>
        <input type="text" v-model="tag.key" class="w-full" :disabled="true" />
      </div>
      <div class="flex flex-col mb-4">
        <label for="name" class="text-xs font-bold">Typ</label>
        <select v-model="tag.type">
          <option value="">Generisch - leer</option>
          <option value="app">Anwendung - app</option>
          <option value="name">Name/Person - name</option>
          <option value="phone">Telefon - phone</option>
        </select>
      </div>
      <div class="flex flex-col mb-4">
        <label for="name" class="text-xs font-bold">Name</label>
        <input type="text" v-model="tag.name" class="w-full" />
      </div>

      <div class="p-2 rounded-lg bg-white mb-4">
        <button
          v-for="roleId in tag.roles"
          :key="`role_${roleId}`"
          @click="removeRole(roleId)"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
        >
          {{ getRoleName(roleId) }}
        </button>
        <select v-model="newRole" ref="newRoleInput">
          <option
            v-for="role in roles"
            :key="`role_${role._id}`"
            :value="role._id"
            >{{ role.name }}</option
          >
        </select>
        <button
          @click="addRole"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-green-600"
        >
          Rolle hinzufügen
        </button>
      </div>

      <div class="bg-gray-100 p-4 rounded-lg">
        <h2 class="font-bold">
          {{ totalArticlesCount }} Dokumente mit diesem Tag
        </h2>
        <v-server-table
          ref="articlesTable"
          :columns="columns"
          :options="options"
          @select="onSelect"
        >
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import { createInstance, resource } from "vuex-pagination"
import axios from "axios"
import Article from "@/models/article"
import authHeader from "../services/auth-header"

import articleLink from "@/components/AdminArticleLink.vue"

const API_URL = process.env.VUE_APP_API_URL
//import { bus } from "@/main";

export default {
  name: "admin-tag",
  components: {
    RoundButton,
  },
  data() {
    return {
      tag: null,
      roles: [],
      newRole: null,
      selectionCount: 0,
      totalArticlesCount: 0,
      columns: ["name", "status", "type", "created"],
      options: {
        uniqueKey: "_id",
        templates: {
          name: articleLink,
          tags: function(h, row, index) {
            return (
              <div class="text-xs">{row.tags.join(",").toUpperCase()} </div>
            )
          },
        },
        async requestFunction(data) {
          const tt = data.query.tags

          data.query.tags =
            tt !== undefined && tt.trim() !== ""
              ? `${this.$route.params.tagKey} ${tt.trim()}`
              : this.$route.params.tagKey
          data.query.allTags = true
          try {
            const response = await axios.get(`${API_URL}admin_articles`, {
              headers: authHeader(),
              params: { ...data },
            })

            const tmpData = response.data
            response.data.data = tmpData.data.map(item => {
              item.created = moment(item.created).format("DD.MM.YYYY HH:mm")
              return item
            })

            return response.data
          } catch (error) {
            console.log(error)
            this.$router.push("/login")
          }
        },
        columnsClasses: {
          name: "text-left",
          type: "text-left",
          status: "text-left",
          created: "text-left",
        },
        filterByColumn: true,
        filterable: ["name", "type", "status", "created"],

        perPage: 25,
        perPageValues: [10, 25, 50, 100],
        pagination: {
          chunk: 10,
          show: true,
          dropdown: false,
          edge: true,
          align: "center",
        },
        dateColumns: ["created"],
        //toMomentFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        //dateFormat: "DD.MM.YYYY",
        selectable: {
          mode: "multiple", // or 'multiple'
          only: function(row) {
            return true // any condition
          },
          selectAllMode: "page", // or 'page',
          programmatic: false,
        },
        texts: {
          loadingError: "Ein Fehler ist aufgetreten.",
          count:
            "Zeige {from} bis {to} von {count} Dokumenten|{count} Dokumente|einem Dokument",
          first: "Anfang",
          last: "Ende",
          filter: "Filter:",
          filterPlaceholder: "Search query",
          limit: "Listen-Einträge:",
          page: "Seite:",
          noResults: "Keine Dokumente gefunden",
          noRequest: "Please select at least one filter to fetch results",
          filterBy: "Nach {column}",
          loading: "Lade...",
          defaultOption: "Select {column}",
          columns: "Spalten",
        },
      },
    }
  },
  computed: {
    tagRoles() {
      return this.tag
        ? this.tag.roles.map(r => {
            return this.getRoleName(r)
          })
        : []
    },
  },
  created() {
    //this.$store.dispatch('getTags');
    //this.$store.dispatch('getArticles');
  },
  updated() {
    //debugger;
  },
  async mounted() {
    console.log("mounted", this.$route.params.tagKey)
    this.fetchRoles()
    this.fetchTag()
  },
  methods: {
    onSelect(selected) {
      this.selectionCount = selected.length
    },
    getRoleName(roleId) {
      if (this.roles === null || roleId === undefined) return ""
      console.log("getRoleName", roleId)
      const role = this.roles.find(role => role._id === roleId)
      return role ? role.name : ""
    },
    async fetchTag() {
      this.tag = null
      const response = await axios.get(
        `${API_URL}admin_tags/${this.$route.params.tagKey}`,
        { headers: authHeader() }
      )
      this.tag = response.data
    },
    async fetchRoles() {
      this.roles = null
      const response = await axios.get(`${API_URL}admin_roles`, {
        headers: authHeader(),
      })
      this.roles = response.data.data.filter(r => r.name !== "admin")
    },
    async addRole() {
      if (this.newRole === null || this.tag.roles.includes(this.newRole)) {
        this.$refs.newRoleInput.focus()
        this.newRole = null
        return
      }
      this.tag.roles.push(this.newRole)

      const response = await axios.patch(
        `${API_URL}admin_tags/${this.tag._id}`,
        this.tag,
        { headers: authHeader() }
      )
    },
    async removeRole(roleId) {
      if (!confirm("Rolle entfernen, sicher?")) return

      this.tag.roles = this.tag.roles.filter(r => r !== roleId)

      const response = await axios.patch(
        `${API_URL}admin_tags/${this.tag._id}`,
        this.tag,
        { headers: authHeader() }
      )
    },
    async updateTag() {
      const response = await axios.patch(
        `${API_URL}admin_tags/${this.tag._id}`,
        this.tag,
        { headers: authHeader() }
      )
      console.log("updateTag", response)
      this.fetchTag()
    },
  },
}
</script>
<style scoped lang="scss">
.logout {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}
.newTag {
  width: 100%;
  margin: 0 auto;
  input {
    padding: 4px;
    margin: 4px;
  }
}
.admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .tags {
    width: 100%;
    margin: 0 auto;
  }
  .wrapper {
    position: absolute;
    background: $grey;
    padding: 30px;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
  }
}
</style>
