<template>
  <div class="overflow-scroll bg-gray-200">
    <div
      class="fixed z-10 w-full flex justify-between items-center px-8 border-b border-black bg-white"
    >
      <div>EWH.Admin - Importe</div>
      <div>
        <div @click="$router.push('/admin')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div class="pt-24 px-8 pb-16">
      <div class="p-4 rounded-lg bg-white">
        <div class="mb-8">
          <button
            @click="showFiles"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
          >
            Upload-Verzeichnis anzeigen
          </button>
          <button
            @click="showFailed"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
          >
            Failed-Verzeichnis anzeigen
          </button>
          <button
            @click="startImport"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-green-500"
          >
            Import starten
          </button>
          <button
            @click="refreshTable"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
          >
            Liste neu laden ({{ countdown }})
          </button>
        </div>
        <div class="">
          <p>Ausgewählte Import-Reporte: {{ selectionCount }}</p>
          <div :class="`${selectionCount === 0 ? 'opacity-25' : ''}`" class="">
            <button
              @click="deleteSelection"
              class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
              :disabled="isSelectionEmpty"
            >
              Import-Reporte löschen
            </button>
          </div>
        </div>
      </div>
      <v-server-table
        ref="importsTable"
        :columns="columns"
        :options="options"
        @select="onSelect"
      >
        <!--div slot="filter__year">
          <select>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
          </select>
          <input type="checkbox" class="form-control" />
        </div-->
      </v-server-table>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL

import RoundButton from "@/components/RoundButton.vue"

import importLink from "@/components/AdminImportLink.vue"

export default {
  name: "admin-imports",
  components: {
    RoundButton,
  },
  data() {
    return {
      timer: null,
      countdown: 10,
      selectionCount: 0,
      columns: ["created", "status", "files"],
      tableData: [],
      options: {
        uniqueKey: "_id",
        headings: {
          created: "Datum",
          status: "Status",
          files: "Dateien",
        },
        templates: {
          created: importLink,
          files: function(h, row, index) {
            return <div class="">{row.files?.length} </div>
          },
        },
        async requestFunction(data) {
          try {
            const response = await axios.get(`${API_URL}admin_imports`, {
              headers: authHeader(),
              params: { ...data },
            })

            const tmpData = response.data
            response.data.data = tmpData.data.map(item => {
              item.created = moment(item.created).format("DD.MM.YYYY HH:mm")
              return item
            })

            return response.data
          } catch (error) {
            console.log(error)
            this.$router.push("/login")
          }
        },
        columnsClasses: {
          files: "text-left",
          status: "text-left",
          created: "text-left",
        },
        filterByColumn: true,
        filterable: ["created", "status"],
        perPage: 25,
        perPageValues: [10, 25, 50, 100],
        pagination: {
          chunk: 10,
          show: true,
          dropdown: false,
          edge: true,
          align: "center",
        },
        dateColumns: ["created"],
        //toMomentFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        dateFormat: "DD.MM.YYYY",
        selectable: {
          mode: "multiple", // or 'multiple'
          only: function(row) {
            return true // any condition
          },
          selectAllMode: "page", // or 'page',
          programmatic: false,
        },
        texts: {
          loadingError: "Ein Fehler ist aufgetreten.",
          count:
            "Zeige {from} bis {to} von {count} Import-Reporten|{count} Import-Reporte|ein Import-Report",
          first: "Anfang",
          last: "Ende",
          filter: "Filter:",
          filterPlaceholder: "Search query",
          limit: "Listen-Einträge:",
          page: "Seite:",
          noResults: "Keine Dokumente gefunden",
          noRequest: "Please select at least one filter to fetch results",
          filterBy: "Nach {column}",
          loading: "Lade...",
          defaultOption: "Select {column}",
          columns: "Columns",
        },
      },
    }
  },
  computed: {
    isSelectionEmpty() {
      return this.$refs.importsTable?.selectedRows?.length === 0
    },
    newImportsDisabled() {
      return this.selectionCount === 0
    },
  },
  created() {},
  updated() {},
  async mounted() {
    this.timer = setInterval(() => {
      this.countdown--
      if (this.countdown === 0) {
        this.$refs.importsTable.refresh()
        this.countdown = 10
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onSelect(selected) {
      this.selectionCount = selected.length
    },

    refreshTable() {
      this.$refs.importsTable.refresh()
      this.countdown = 10
    },

    async showFiles() {
      const response = await axios.get(`${API_URL}admin_imports_files`, {
        headers: authHeader(),
      })

      let text = `${response.data.freeSpace} freier Speicher\n\n`
      text += `${response.data.totalCount} Datei(en) in Verzeichnis /ftp/files/ gefunden:\n\n`
      let index = 1
      for (const file of response.data.fileNames) {
        text += `${index}: ${file}\n`
        index++
      }
      alert(text)
    },

    async showFailed() {
      const response = await axios.get(`${API_URL}admin_imports_failed`, {
        headers: authHeader(),
      })
      let text = `${response.data.freeSpace} freier Speicher\n\n`
      text += `${response.data.totalCount} Datei(en) in Verzeichnis /ftp/failed/ gefunden:\n\n`

      let index = 1
      for (const file of response.data.fileNames) {
        text += `${index}: ${file}\n`
        index++
      }
      alert(text)
    },

    async deleteSelection() {
      const selected = this.$refs.importsTable.selectedRows
      if (selected.length === 0) return
      if (!confirm(`${selected.length} Import-Reporte löschen, sicher?`)) return

      const response = await axios.post(
        `${API_URL}admin_imports_batch_delete`,
        {
          importIds: selected.map(s => s._id),
        },
        {
          headers: authHeader(),
        }
      )
      if (response.status === 204) {
        this.$refs.importsTable.resetSelectedRows()
        this.$refs.importsTable.refresh()
      }
    },
    async startImport() {
      // check if new imports are disabled
      const responseCheck = await axios.get(
        `${API_URL}admin_imports_allow_new`,
        {
          headers: authHeader(),
        }
      )

      if (responseCheck.data.allow_new === false) {
        alert("Neue Importe sind nicht erlaubt.")
        return
      }

      const response = await axios
        .post(`${API_URL}admin_imports_start`, {}, { headers: authHeader() })
        .catch(error => {
          console.log(error)
          this.$router.push("/login")
        })
      if (response.status === 200) {
        this.$refs.importsTable.refresh()
      }
    },
  },
}
</script>
<style lang="scss">
th.VueTables__select-all {
  @apply text-left;
}
li.VuePagination__pagination-item a {
  width: auto !important;
}
div.VuePagination.is-centered {
  justify-content: center;
}
p.VuePagination__count {
  justify-content: center;
}
</style>
