<template>
  <button
    :class="clazz"
    :title="text"
    @click.prevent="$emit('onClick')"
  ></button>
</template>

<script>
export default {
  name: "RoundButton",
  props: {
    icon: {
      type: String,
    },
    color: {
      type: String,
      default: "orange",
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  computed: {
    clazz() {
      let cn = []
      cn.push(this.color)
      cn.push(this.icon)

      return cn.join(" ")
    },
  },

  methods: {},
}
</script>

<style scoped lang="scss">
button {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  border: none;
  outline: none;
  cursor: pointer;

  &.orange {
    background: $orange;
    &:active {
      background: darken($orange, 10%);
    }
  }
  &.green {
    background: $green;
    &:active {
      background: darken($green, 10%);
    }
  }
  &.yellow {
    background: $yellow;
    &:active {
      background: darken($yellow, 10%);
    }
  }
  &.blue {
    background: $blue;
    &:active {
      background: darken($blue, 10%);
    }
  }

  &.search {
    background-image: url(../assets/icons/go.svg);
  }
  &.expand {
    background-image: url(../assets/icons/go.svg);
    transform: rotate(90deg);
  }
  &.close {
    background-image: url(../assets/icons/close.svg);
  }
  &.reset {
    background-image: url(../assets/icons/reset.svg);
  }
  &.down {
    background-image: url(../assets/icons/download.svg);
  }
  &.link {
    background-image: url(../assets/icons/link.svg);
  }
  &.zoom {
    background-image: url(../assets/icons/zoom.svg);
  }
  &.prev {
    background-image: url(../assets/icons/prev.svg);
  }
  &.next {
    background-image: url(../assets/icons/next.svg);
  }
  &.up {
    background-image: url(../assets/icons/go.svg);
    transform: rotate(-90deg);
  }
  &.listup {
    background-image: url(../assets/icons/next.svg);
    transform: rotate(-90deg);
  }
  &.listdown {
    background-image: url(../assets/icons/next.svg);
    transform: rotate(90deg);
  }
  &.listfirst {
    background-image: url(../assets/icons/first.svg);
    transform: rotate(90deg);
  }
  &.first {
    background-image: url(../assets/icons/first.svg);
  }
  &.open {
    background-image: url(../assets/icons/open.svg);
  }
  &.plus {
    background-image: url(../assets/icons/plus.svg);
  }
  &.minus {
    background-image: url(../assets/icons/minus.svg);
  }
  &.play {
    background-image: url(../assets/icons/play.svg);
  }
  &.pause {
    background-image: url(../assets/icons/pause.svg);
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}
</style>
