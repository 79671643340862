<script>
export default {
  props: ["data", "index"],
}
</script>

<template>
  <a
    class="underline cursor-pointer"
    @click="$router.push(`/admin/imports/${data._id}`)"
    >{{ data.created }}</a
  >
</template>
