<script>
export default {
  props: ["data", "index"],
}
</script>

<template>
  <a
    class="underline cursor-pointer"
    @click="$router.push(`/admin/articles/${data._id}`)"
    >{{ data.name }}</a
  >
</template>
