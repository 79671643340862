<template>
  <div class="admin">
    <div class="header flex justify-between items-center px-8">
      <div>EWH.Admin - Users/Roles</div>
      <div class="right">
        <div @click="$router.push('/admin')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div ref="wrapper" class="wrapper">
      <div class="flex flex-col items-center"></div>

      <v-server-table
        ref="rolesTable"
        :columns="columns"
        :options="options"
        @select="onSelect"
      >
        <!--div slot="filter__year">
          <select>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
          </select>
          <input type="checkbox" class="form-control" />
        </div-->
      </v-server-table>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import { createInstance, resource } from "vuex-pagination"

import axios from "axios"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL

//import Article from '@/models/article';

//import { bus } from "@/main";

export default {
  name: "archive",
  components: {
    RoundButton,
  },
  data() {
    return {
      selectionCount: 0,
      columns: ["name", "tag", "_id"],
      tableData: [],
      options: {
        uniqueKey: "_id",
        templates: {
          //key: tagLink,
        },
        async requestFunction(data) {
          try {
            const response = await axios.get(`${API_URL}admin_roles`, {
              headers: authHeader(),
              params: { ...data },
            })

            debugger

            const tmpData = response.data
            response.data.data = tmpData.data.map(item => {
              item.created = moment(item.created).format("DD.MM.YYYY HH:mm")
              return item
            })

            return response.data
          } catch (error) {
            console.log(error)
            this.$router.push("/login")
          }
        },
        columnsClasses: {
          name: "text-left",
          _id: "text-left",
          tag: "text-left",
        },
        perPage: 25,
        filterable: false,

        texts: {
          loadingError: "Ein Fehler ist aufgetreten.",
          count:
            "Zeige {from} bis {to} von {count} Tags|{count} Tags|einem Tag",
          first: "Anfang",
          last: "Ende",
          filter: "Filter:",
          filterPlaceholder: "Search query",
          limit: "Listen-Einträge:",
          page: "Seite:",
          noResults: "Keine Dokumente gefunden",
          noRequest: "Please select at least one filter to fetch results",
          filterBy: "Nach {column}",
          loading: "Lade...",
          defaultOption: "Select {column}",
          columns: "Columns",
        },
      },
    }
  },
  computed: {},
  created() {},
  updated() {},
  async mounted() {},
  methods: {
    onSelect(selection) {
      this.selectionCount = selection.length
    },
    getUserTag(roleName) {
      switch (roleName) {
        case "business":
          return "BUS"
        case "literatur":
          return "LIT"
        case "privat":
          return "PRI"
      }
    },
    refreshTags() {
      resource("tags").refresh()
    },
    saveTag(opts) {
      console.log(opts)
      debugger
    },
    updateTag(key, name, roleId) {
      TagService.updateTag(key, name, roleId).then(
        () => {
          this.refreshTags()
        },
        () => {
          debugger
        }
      )
    },
    editTag(tag) {
      console.log(tag)
      debugger
    },
    deleteTag(id) {
      if (confirm("Sicher?")) {
        TagService.deleteTag(id).then(
          () => {
            this.refreshTags()
          },
          () => {
            debugger
          }
        )
      }
    },
    createTag() {
      if (this.nkey === "" || this.nname === "") return
      TagService.createTag(this.nkey, this.nname).then(
        () => {
          this.refreshTags()
          this.nname = ""
          this.nkey = ""
        },
        () => {
          debugger
        }
      )
    },
  },
}
</script>
<style scoped lang="scss">
.logout {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}
.newTag {
  width: 100%;
  margin: 0 auto;
  input {
    padding: 4px;
    margin: 4px;
  }
}
.admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .tags {
    width: 100%;
    margin: 0 auto;
  }
  .wrapper {
    position: absolute;
    background: $grey;
    padding: 30px;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
  }
}
</style>
