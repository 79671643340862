<script>
export default {
  props: ["data", "index"],
}
</script>

<template>
  <a
    class="underline cursor-pointer"
    @click="$router.push(`/admin/tags/${data.key}`)"
    >{{ data.key }}</a
  >
</template>
