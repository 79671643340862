import Vue from "vue"
import axios from "axios"
import authHeader from "./auth-header"

import { bus } from "@/main"

const API_URL = process.env.VUE_APP_API_URL

class ArticleService {
  getStats() {
    return axios.get(`${API_URL}articles_stats`, { headers: authHeader() })
  }

  getArticles() {
    return axios.get(`${API_URL}articles`, { headers: authHeader() })
  }

  getArticle(hash) {
    return axios.get(`${API_URL}articles/${hash}`, { headers: authHeader() })
  }

  updateArticle(hash, link, content, tags) {
    const data = {
      link,
      content,
      tags,
    }
    return axios.post(`${API_URL}articles/${hash}`, data, {
      headers: authHeader(),
    })
  }

  rateArticle(hash, rating) {
    const data = {
      rating,
    }
    return axios.post(`${API_URL}articles/${hash}`, data, {
      headers: authHeader(),
    })
  }

  commentArticle(hash, notes) {
    const data = {
      notes,
    }
    return axios.post(`${API_URL}articles/${hash}`, data, {
      headers: authHeader(),
    })
  }

  deleteArticle(hash) {
    return axios.delete(`${API_URL}articles/${hash}`, {
      headers: authHeader(),
    })
  }

  upload(data) {
    const hds = authHeader()
    hds["content-type"] = "multipart/form-data"
    return axios
      .post(API_URL + "upload", data, { headers: hds })
      .then(res => {
        console.log(res)
        debugger
      })
      .catch(err => {
        if (err.response.status === 401) {
          bus.$emit("logOut")
          Vue.notify({
            group: "alerts",
            title: "Unauthorized",
            text: "Please log in",
          })
        }
        //console.log(err);
        //debugger;
      })
  }

  import() {
    return axios
      .post(API_URL + "import", null, { headers: authHeader() })
      .then(res => {
        console.log(res)
        debugger
      })
      .catch(err => {
        if (err.response.status === 401) {
          bus.$emit("logOut")
          Vue.notify({
            group: "alerts",
            title: "Unauthorized",
            text: "Please log in",
          })
        }
        //console.log(err);
        //debugger;
      })
  }

  processInbox() {
    return axios
      .post(API_URL + "import", null, { headers: authHeader() })
      .then(res => {
        console.log(res)
        debugger
      })
      .catch(err => {
        if (err.response.status === 401) {
          bus.$emit("logOut")
          Vue.notify({
            group: "alerts",
            title: "Unauthorized",
            text: "Please log in",
          })
        }
        //console.log(err);
        //debugger;
      })
  }

  // eslint-disable-next-line
  searchArticles(searchObject) {
    return axios.get(API_URL, {
      headers: authHeader(),
      params: {
        s: searchObject.k,
      },
    })
  }
}

export default new ArticleService()
