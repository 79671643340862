<template>
  <div class="overflow-scroll bg-gray-200">
    <div
      class="fixed z-10 w-full flex justify-between items-center px-8 border-b border-black bg-white"
    >
      <div>EWH.Admin - Tags</div>
      <div>
        <div @click="$router.push('/admin')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div class="pt-24 px-8 pb-16">
      <div class="bg-gray-100 p-2 rounded-lg mb-4">
        <h2 class="mb-2">Neues Tag erstellen</h2>
        <div>
          <input
            ref="newKeyInput"
            type="text"
            v-model="newKey"
            placeholder="Tag-Key"
            class="w-48"
          />
          <input
            ref="newNameInput"
            type="text"
            v-model="newName"
            placeholder="Tag-Name"
            class="w-48"
          />
          <select v-model="newType">
            <option value="">Generisch - leer</option>
            <option value="app">Anwendung - app</option>
            <option value="name">Name/Person - name</option>
            <option value="phone">Telefon - phone</option>
          </select>
          <button
            @click="createTag"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-green-600"
          >
            Tag erstellen
          </button>
        </div>
      </div>
      <div class="">
        <p>Ausgewählte Tags: {{ selectionCount }}</p>
        <div
          :class="`${selectionCount === 0 ? 'opacity-25' : ''}`"
          class="mb-8"
        >
          <button
            @click="deleteSelection"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
            :disabled="isSelectionEmpty"
          >
            Tags löschen
          </button>
        </div>
      </div>
      <v-server-table
        ref="tagsTable"
        :columns="columns"
        :options="options"
        @select="onSelect"
      >
        <!--div slot="filter__year">
          <select>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
          </select>
          <input type="checkbox" class="form-control" />
        </div-->
      </v-server-table>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL

import RoundButton from "@/components/RoundButton.vue"

import tagLink from "@/components/AdminTagLink.vue"
import tagRoles from "@/components/AdminTagRoles.vue"

export default {
  name: "admin-tags",
  components: {
    RoundButton,
  },
  data() {
    return {
      roles: [],
      selectionCount: 0,
      columns: ["key", "name", "type", "roles"],
      tableData: [],
      options: {
        uniqueKey: "_id",
        templates: {
          key: tagLink,
          roles: tagRoles,
        },
        headings: {
          type: "Typ",
        },
        async requestFunction(data) {
          try {
            const response = await axios.get(`${API_URL}admin_tags`, {
              headers: authHeader(),
              params: { ...data },
            })

            const tmpData = response.data
            response.data.data = tmpData.data.map(function(item) {
              item.created = moment(item.created).format("DD.MM.YYYY HH:mm")
              return item
            })
            return response.data
          } catch (error) {
            console.log(error)
            this.$router.push("/login")
          }
        },
        columnsClasses: {
          name: "text-left",
          type: "text-left",
          key: "text-left",
          created: "text-left",
        },
        filterByColumn: true,
        filterable: ["name", "key", "type", "created"],
        perPage: 25,
        perPageValues: [10, 25, 50, 100],
        pagination: {
          chunk: 10,
          show: true,
          dropdown: false,
          edge: true,
          align: "center",
        },
        dateColumns: ["created"],
        //toMomentFormat: "YYYY-MM-DDTHH:mm:ss.SSSZ",
        dateFormat: "DD.MM.YYYY",
        selectable: {
          mode: "multiple", // or 'multiple'
          only: function(row) {
            return true // any condition
          },
          selectAllMode: "page", // or 'page',
          programmatic: false,
        },
        texts: {
          loadingError: "Ein Fehler ist aufgetreten.",
          count:
            "Zeige {from} bis {to} von {count} Tags|{count} Tags|einem Tag",
          first: "Anfang",
          last: "Ende",
          filter: "Filter:",
          filterPlaceholder: "Search query",
          limit: "Listen-Einträge:",
          page: "Seite:",
          noResults: "Keine Dokumente gefunden",
          noRequest: "Please select at least one filter to fetch results",
          filterBy: "Nach {column}",
          loading: "Lade...",
          defaultOption: "Select {column}",
          columns: "Columns",
        },
      },
      newKey: "",
      newName: "",
      newType: "",
    }
  },
  computed: {
    isSelectionEmpty() {
      return this.$refs.articlesTable?.selectedRows?.length === 0
    },
  },
  created() {},
  updated() {},
  async mounted() {
    debugger
  },
  methods: {
    onSelect(selected) {
      this.selectionCount = selected.length
    },
    async fetchRoles() {
      this.roles = null
      const response = await axios.get(`${API_URL}admin_roles`, {
        headers: authHeader(),
      })
      this.roles = response.data.data.filter(r => r.name !== "admin")
    },
    getRoleName(roleId) {
      if (this.roles === null || roleId === undefined) return ""
      const role = this.roles.find(role => role._id === roleId)
      return role ? role.name : ""
    },

    async createTag() {
      if (this.newKey.trim() === "") {
        this.$refs.newKeyInput.focus()
        return
      }
      if (this.newName.trim() === "") {
        this.$refs.newNameInput.focus()
        return
      }

      const response = await axios.post(
        `${API_URL}admin_tags`,
        {
          key: this.newKey,
          name: this.newName,
          type: this.newType,
        },
        {
          headers: authHeader(),
        }
      )
      if (response.status === 200) {
        this.$refs.tagsTable.resetSelectedRows()
        this.$refs.tagsTable.refresh()
        this.newKey = ""
        this.newName = ""
        this.newType = ""
      }
    },

    async deleteSelection() {
      const selected = this.$refs.tagsTable.selectedRows
      if (selected.length === 0) return
      if (!confirm(`${selected.length} Tags löschen, sicher?`)) return

      const response = await axios.post(
        `${API_URL}admin_tags_batch_delete`,
        {
          tagIds: selected.map(s => s._id),
        },
        {
          headers: authHeader(),
        }
      )
      if (response.status === 204) {
        this.$refs.tagsTable.resetSelectedRows()
        this.$refs.tagsTable.refresh()
      }
    },
  },
}
</script>
<style lang="scss">
li.VuePagination__pagination-item a {
  width: auto !important;
}
div.VuePagination.is-centered {
  justify-content: center;
}
p.VuePagination__count {
  justify-content: center;
}
</style>
