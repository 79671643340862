import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Login from "../views/Login.vue"
import Register from "../views/Register.vue"
import AdminIndex from "../views/AdminIndex.vue"
import AdminArticles from "../views/AdminArticles.vue"
import AdminArticle from "../views/AdminArticle.vue"
import AdminTags from "../views/AdminTags.vue"
import AdminTag from "../views/AdminTag.vue"
import AdminImports from "../views/AdminImports.vue"
import AdminImport from "../views/AdminImport.vue"
import AdminRoles from "../views/AdminRoles.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/admin",
    component: AdminIndex,
  },
  {
    path: "/admin/articles",
    component: AdminArticles,
  },
  {
    path: "/admin/articles/:articleId",
    component: AdminArticle,
  },
  {
    path: "/admin/tags",
    component: AdminTags,
  },
  {
    path: "/admin/tags/:tagKey",
    component: AdminTag,
  },
  {
    path: "/admin/imports",
    component: AdminImports,
  },
  {
    path: "/admin/imports/:importId",
    component: AdminImport,
  },
  {
    path: "/admin/roles",
    component: AdminRoles,
  },
  {
    path: "/archive",
    name: "archive",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "archive" */ "../views/Archive.vue"),
  },
  {
    path: "/archive/:hash",
    name: "details",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: true,
    component: () =>
      import(/* webpackChunkName: "archive" */ "../views/DetailsView.vue"),
  },
  {
    path: "/import",
    name: "import",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Import.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  saveScrollPosition: true,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("user")

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login")
  } else {
    next()
  }
})

export default router
