<template>
  <div class="admin">
    <div class="header flex justify-between items-center px-8">
      <div>EWH.Admin</div>
      <div class="right">
        <div @click="$router.push('/archive')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div ref="wrapper" class="wrapper">
      <div class="flex flex-col items-center">
        <a
          class="bg-stone-900 p-4 text-white mb-4 cursor-pointer"
          @click="$router.push('/admin/imports')"
          >Importe: {{ stats.importsTotalCount }}</a
        >
        <a
          class="bg-stone-900 p-4 text-white mb-4 cursor-pointer"
          @click="$router.push('/admin/articles')"
          >Dokumente: {{ stats.articlesTotalCount }}</a
        >
        <a
          class="bg-stone-900 p-4 text-white mb-4 cursor-pointer"
          @click="$router.push('/admin/tags')"
          >Tags: {{ stats.tagsTotalCount }}</a
        >
        <a
          class="bg-stone-900 p-4 text-white mb-4 cursor-pointer"
          @click="$router.push('/admin/roles')"
          >Benutzer-Rollen: {{ stats.rolesTotalCount }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import { createInstance, resource } from "vuex-pagination"

import axios from "axios"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL

//import Article from '@/models/article';

//import { bus } from "@/main";

export default {
  name: "archive",
  components: {
    RoundButton,
  },
  data() {
    return {
      users: [],
      roles: new Array(),
      scrollPos: 0,
      currentPage: 1,
      nkey: "",
      nname: "",
      stats: {},
    }
  },
  computed: {},
  created() {},
  updated() {},
  async mounted() {
    const response = await axios.get(`${API_URL}admin_stats`, {
      headers: authHeader(),
    })

    this.stats = response.data
  },
  methods: {
    getUserTag(roleName) {
      switch (roleName) {
        case "business":
          return "BUS"
        case "literatur":
          return "LIT"
        case "privat":
          return "PRI"
      }
    },
    refreshTags() {
      resource("tags").refresh()
    },
    saveTag(opts) {
      console.log(opts)
      debugger
    },
    updateTag(key, name, roleId) {
      TagService.updateTag(key, name, roleId).then(
        () => {
          this.refreshTags()
        },
        () => {
          debugger
        }
      )
    },
    editTag(tag) {
      console.log(tag)
      debugger
    },
    deleteTag(id) {
      if (confirm("Sicher?")) {
        TagService.deleteTag(id).then(
          () => {
            this.refreshTags()
          },
          () => {
            debugger
          }
        )
      }
    },
    createTag() {
      if (this.nkey === "" || this.nname === "") return
      TagService.createTag(this.nkey, this.nname).then(
        () => {
          this.refreshTags()
          this.nname = ""
          this.nkey = ""
        },
        () => {
          debugger
        }
      )
    },
  },
}
</script>
<style scoped lang="scss">
.logout {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}
.newTag {
  width: 100%;
  margin: 0 auto;
  input {
    padding: 4px;
    margin: 4px;
  }
}
.admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .tags {
    width: 100%;
    margin: 0 auto;
  }
  .wrapper {
    position: absolute;
    background: $grey;
    padding: 30px;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
  }
}
</style>
