<template>
  <div class="admin">
    <div class="header flex justify-between items-center px-8">
      <div>
        <span>
          EWH.Admin - Dokument bearbeiten
        </span>
        <div class="inline-block p-4">
          <button
            @click="$router.push(`/archive/${article.hash}`)"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
          >
            Archiv-Eintrag anzeigen
          </button>
          <a
            :href="awsResourceUrl"
            target="_blank"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
          >
            AWS-Resource anzeigen
          </a>
        </div>
      </div>
      <div class="right flex">
        <div @click="$router.back()" class="py-2 mr-2">
          <RoundButton color="orange" icon="prev" text="Zurück" />
        </div>
        <div @click="$router.push('/admin/articles')" class="py-2">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div ref="wrapper" class="wrapper" v-if="article !== null">
      <div class="flex flex-col mb-4">
        <label for="name" class="text-xs font-bold">Name</label>
        <input
          type="text"
          v-model="article.name"
          class="w-full"
          :disabled="true"
        />
      </div>

      <div class="flex">
        <div class="flex flex-col mb-4 w-1/4">
          <label for="type" class="text-xs font-bold">Status</label>
          <input
            type="text"
            v-model="article.status"
            class="w-full"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col mb-4 w-1/4">
          <label for="type" class="text-xs font-bold">Typ</label>
          <input
            type="text"
            v-model="article.type"
            class="w-full"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col mb-4 w-1/4">
          <label for="ext" class="text-xs font-bold">Datei-Endung</label>
          <input
            type="text"
            v-model="article.ext"
            class="w-full"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col mb-4 w-1/4">
          <label for="pages" class="text-xs font-bold">Seitenanzahl</label>
          <input
            type="text"
            v-model="article.pages"
            class="w-full"
            :disabled="true"
          />
        </div>
      </div>

      <div class="flex flex-col mb-4">
        <label for="hash" class="text-xs font-bold">Hash-Wert</label>
        <input
          type="text"
          v-model="article.hash"
          class="w-full"
          :disabled="true"
        />
      </div>

      <div class="flex">
        <div class="flex flex-col mb-4 w-1/3">
          <label for="hash" class="text-xs font-bold">Jahr</label>
          <input type="text" v-model="article.year" class="w-full" />
        </div>
        <div class="flex flex-col mb-4  w-1/3">
          <label for="hash" class="text-xs font-bold">Monat</label>
          <input type="text" v-model="article.month" class="w-full" />
        </div>
        <div class="flex flex-col mb-4  w-1/3">
          <label for="hash" class="text-xs font-bold">Tag</label>
          <input type="text" v-model="article.day" class="w-full" />
        </div>
      </div>

      <div class="flex">
        <div class="flex flex-col mb-4 w-1/3">
          <label for="rating" class="text-xs font-bold">Bewertung</label>
          <input type="text" v-model="article.rating" class="w-full" />
        </div>
        <div class="flex flex-col mb-4  w-1/3">
          <label for="lat" class="text-xs font-bold">Latitude</label>
          <input type="text" v-model="article.lat" class="w-full" />
        </div>
        <div class="flex flex-col mb-4  w-1/3">
          <label for="lon" class="text-xs font-bold">Longitude</label>
          <input type="text" v-model="article.lon" class="w-full" />
        </div>
      </div>

      <div class="flex flex-col mb-4">
        <label for="name" class="text-xs font-bold"
          >Link zu Eintrag auf ewh.de</label
        >
        <input type="text" v-model="article.link" class="w-full" />
      </div>

      <label class="text-xs font-bold">Tags</label>
      <div class="p-4 bg-white rounded-lg mb-4">
        <div
          v-for="tag in article.tags"
          :key="`tag_${tag}`"
          class="bg-gray-100 p-1 mb-1"
        >
          <span class="w-96 inline-block"
            >{{ tag }} - {{ getTagName(tag) }}</span
          >
          <button
            @click="$router.push(`/admin/tags/${tag}`)"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-blue-500"
          >
            Tag anzeigen
          </button>
          <button
            @click="removeTag(tag)"
            class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-500"
          >
            Tag entfernen
          </button>
        </div>

        <div class="bg-gray-100 p-1 mb-1">
          <button
            @click="isAddingTag = !isAddingTag"
            :class="
              `border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-${
                isAddingTag ? 'red' : 'blue'
              }-500`
            "
          >
            {{ isAddingTag ? "Abbrechen" : "Neues Tag hinzufügen" }}
          </button>
          <div v-show="isAddingTag" class="bg-gray-100 p-2 rounded-lg">
            <button
              v-for="tag in allTags"
              :key="tag._id"
              @click="addTag(tag)"
              class="border border-black bg-gray-300 px-4 rounded mr-2 mb-2 hover:bg-green-500"
            >
              {{ tag.key }} - {{ tag.name }}
            </button>
          </div>
        </div>
      </div>

      <div class="p-2 rounded-lg bg-white mb-4">
        {{ article.roles }}
        <button
          v-for="roleId in article.roles"
          :key="`role_${roleId}`"
          @click="removeRole(roleId)"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-600"
        >
          {{ getRoleName(roleId) }}
        </button>
        <select v-model="newRole" ref="newRoleInput">
          <option
            v-for="role in roles"
            :key="`role_${role._id}`"
            :value="role._id"
            >{{ role.name }}</option
          >
        </select>
        <button
          @click="addRole"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-green-600"
        >
          Rolle hinzufügen
        </button>
      </div>

      <div class="flex flex-col mb-4 w-full">
        <label for="notes" class="text-xs font-bold">Notizen</label>
        <textarea v-model="article.notes" class="w-full" />
      </div>

      <div class="flex flex-col mb-4 w-full">
        <label for="content" class="text-xs font-bold">Text-Inhalt</label>
        <textarea v-model="article.content" class="w-full" />
      </div>

      <div class="p-4 bg-white rounded-lg mb-4">
        <button
          @click="updateArticle"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-green-600"
        >
          Dokument speichern
        </button>
        <button
          @click="deleteArticle"
          class="border border-black bg-gray-300 px-4 rounded mr-2 hover:bg-red-500"
        >
          Dokument löschen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import { createInstance, resource } from "vuex-pagination"
import axios from "axios"
import Article from "@/models/article"
import authHeader from "../services/auth-header"
const API_URL = process.env.VUE_APP_API_URL
//import { bus } from "@/main";

export default {
  name: "admin-article",
  components: {
    RoundButton,
  },
  data() {
    return {
      awsBucketName: process.env.VUE_APP_AWS_BUCKET_NAME,
      article: null,
      allTags: [],
      selectedTagNew: "",
      roles: [],
      newRole: null,
      isAddingTag: false,
    }
  },
  computed: {
    awsResourceUrl() {
      if (!this.article) return ""
      const prefixMedia = ["text", "image"].includes(this.article.type)
        ? ""
        : `${this.article.type}/`
      const prefixYear = this.article.year || "XXXX"
      const prefixHash = this.article.hash

      return `https://eu-central-1.console.aws.amazon.com/s3/buckets/${this.awsBucketName}?region=eu-central-1&bucketType=general&prefix=${prefixMedia}${prefixYear}/${prefixHash}/`
    },
  },
  created() {
    //this.$store.dispatch('getTags');
    //this.$store.dispatch('getArticles');
  },
  updated() {
    //debugger;
  },
  async mounted() {
    const response = await axios.get(
      `${API_URL}admin_articles/${this.$route.params.articleId}`,
      { headers: authHeader() }
    )
    this.article = response.data

    const responseTags = await axios.get(`${API_URL}admin_tags?limit=200`, {
      headers: authHeader(),
    })
    this.allTags = responseTags.data.data

    await this.fetchRoles()
  },
  methods: {
    getTagName(tagKey) {
      if (this.allTags.length === 0) return ""
      return this.allTags.find(tag => tag.key === tagKey).name
    },
    getRoleName(roleId) {
      if (this.roles === null || roleId === undefined) return ""
      console.log("getRoleName", roleId)
      const role = this.roles.find(role => role._id === roleId)
      return role ? role.name : ""
    },
    async fetchArticle() {
      this.article = null
      const response = await axios.get(
        `${API_URL}admin_articles/${this.$route.params.articleId}`,
        { headers: authHeader() }
      )
      this.article = response.data
    },
    async fetchRoles() {
      this.roles = null
      const response = await axios.get(`${API_URL}admin_roles`, {
        headers: authHeader(),
      })
      this.roles = response.data.data.filter(r => r.name !== "admin")
    },
    async addRole() {
      if (this.newRole === null || this.tag.roles.includes(this.newRole)) {
        this.$refs.newRoleInput.focus()
        this.newRole = null
        return
      }
      this.tag.roles.push(this.newRole)

      const response = await axios.patch(
        `${API_URL}admin_tags/${this.tag._id}`,
        this.tag,
        { headers: authHeader() }
      )
    },
    async removeRole(roleId) {
      if (!confirm("Rolle entfernen, sicher?")) return

      this.tag.roles = this.tag.roles.filter(r => r !== roleId)

      const response = await axios.patch(
        `${API_URL}admin_tags/${this.tag._id}`,
        this.tag,
        { headers: authHeader() }
      )
    },
    async removeTag(tag) {
      try {
        const response = await axios.delete(
          `${API_URL}admin_articles_removetags`,
          {
            data: {
              articleIds: [this.$route.params.articleId],
              tags: [tag],
            },
            headers: authHeader(),
          }
        )
        if (response.status === 204) {
          await this.fetchArticle()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async addTag(tag) {
      try {
        const response = await axios.post(
          `${API_URL}admin_articles_addtags`,
          {
            articleIds: [this.$route.params.articleId],
            tags: [tag.key],
          },
          { headers: authHeader() }
        )
        if (response.status === 204) {
          await this.fetchArticle()
          this.isAddingTag = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    async deleteArticle() {
      if (!confirm("Dokument wird gelöscht, sicher?")) return

      const response = await axios.delete(
        `${API_URL}admin_articles/${this.$route.params.articleId}`,
        { headers: authHeader() }
      )
      if (response.status === 204) {
        this.$router.push("/admin/articles")
      }
    },
    async updateArticle() {
      if (!confirm("Dokument wird aktualisiert, sicher?")) return

      const response = await axios.patch(
        `${API_URL}admin_articles/${this.$route.params.articleId}`,
        this.article,
        { headers: authHeader() }
      )
    },
  },
}
</script>
<style scoped lang="scss">
.logout {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}
.newTag {
  width: 100%;
  margin: 0 auto;
  input {
    padding: 4px;
    margin: 4px;
  }
}
.admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .tags {
    width: 100%;
    margin: 0 auto;
  }
  .wrapper {
    position: absolute;
    background: $grey;
    padding: 30px;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
  }
}
</style>
